var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_vm._m(0),_c('form',{staticClass:"login__form",on:{"submit":function($event){$event.preventDefault();return _vm.loginUser.apply(null, arguments)}}},[_c('FormGroup',[_c('Field',{attrs:{"placeholder":"Email","error-text":(_vm.$v.loginModel.email.$dirty && !_vm.$v.loginModel.email.required
            ? 'Поле не заполнено'
            : null) ||
          (_vm.$v.loginModel.email.$dirty && _vm.$v.loginModel.email
            ? "Введите корректный e-mail"
            : null),"has-error":_vm.$v.loginModel.email.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.loginModel.email.$touch()}},model:{value:(_vm.loginModel.email),callback:function ($$v) {_vm.$set(_vm.loginModel, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"loginModel.email"}})],1),_c('FormGroup',{staticClass:"login__group"},[_c('Field',{attrs:{"placeholder":"Пароль","type":"password","error-text":(_vm.$v.loginModel.password.$dirty && !_vm.$v.loginModel.password.required
            ? 'Поле не заполнено'
            : null) ||
          (_vm.$v.loginModel.password.$dirty && !_vm.$v.loginModel.password.minLength
            ? ("Минимальное количество символов " + (_vm.$v.loginModel.password.$params.minLength.min))
            : null),"has-error":_vm.$v.loginModel.password.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.loginModel.password.$touch()}},model:{value:(_vm.loginModel.password),callback:function ($$v) {_vm.$set(_vm.loginModel, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"loginModel.password"}})],1),_c('BaseButton',{staticClass:"login__submit",attrs:{"type":"submit"}},[_vm._v("Войти")]),_c('BaseButton',{attrs:{"color":"gray"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'Register' })}}},[_vm._v(" Регистрация ")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login__title"},[_vm._v(" Вход "),_c('br'),_vm._v(" для арендодателей ")])}]

export { render, staticRenderFns }